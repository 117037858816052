import { Form, Input } from "antd";
import { GTMolecularRunFn } from "../../components/GTMolecularRunFn";

const FN_NAME = "gtm-run-push-seq-selections";

let pushSeqSelFormItems = (
  <>
    <Form.Item
      label="Sequencing Selections"
      name="seqSelections"
      required="true"
    >
      <Input.TextArea />
    </Form.Item>
  </>
);

function FnRun() {
  return <GTMolecularRunFn name={FN_NAME} formItems={pushSeqSelFormItems} />;
}

export { FnRun };
