import { Row, Col, Typography } from "antd";

const { Text } = Typography;

export default function FedexDelaysNoticeBanner() {
  return (
    <Row className="warning-banner block text-xl px-4 py-2">
      <Col>
        <Text class="text-warning">
          Due to natural occurrences affecting FedEx operations in California
          and the Midwest, please expect potential delays in data delivery
          times.
          <br />
          For the most up-to-date information, please visit -{" "}
          <a href="https://support.biobot.io/hc/en-us/articles/29111028482583-Service-Alert-FedEx-Operational-Disruptions-Affecting-Shipment-Timelines-and-Data-Delivery">
            Biobot Analytics Service Alerts
          </a>
        </Text>
      </Col>
    </Row>
  );
}
