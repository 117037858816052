import { Col as Column, Row, Button, Form, Spin, message } from "antd";
import _ from "lodash";

import { useState } from "react";

import { runFn } from "../helpers/run-function";

import { InitialsParameter } from "./InitialsParameter";

function GTMolecularRunFn(props) {
  const { name } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const withHandling = (fn) => async (values) => {
    setLoading(true);

    try {
      await fn(values);

      form.resetFields();
    } catch (error) {
      message.error("Something went wrong!");

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFormSubmit = withHandling(async (values) => {
    const { initials: author, ...fields } = values;
    const runParams = { author, ...fields };
    return await runFn(
      name,
      { parameters: runParams },
      { download: false }
    ).then((resp) => {
      if (resp.data.errors?.length > 0) {
        message.warning(`Encountered error(s): ${resp.data.errors}`);
      } else if (!_.isNil(resp.data.data?.success) && !resp.data.data.success) {
        message.error(`Unexpected error - seek help from #eng-support!`);
      } else {
        message.success("Successful execution.");
      }
    });
  });

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={onFormSubmit}>
        <Row gutter={12}>
          <Column span={12}>{props.formItems}</Column>
        </Row>

        <Row gutter={12}>
          <Column span={6}>
            <InitialsParameter />
          </Column>
        </Row>

        <Row gutter={12}>
          <Column span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Run
              </Button>
            </Form.Item>
          </Column>
        </Row>
      </Form>
    </Spin>
  );
}

export { GTMolecularRunFn };
